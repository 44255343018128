import { Injectable } from '@angular/core';

import { ProjectMemberRole } from 'src/app/constants';
import { ListRequest, ListResponse, Project, ProjectMember, ServiceAccount } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends GenericObjectsService<Project> {
  protected baseUrl = '/projects';

  listForUser(userId: string, options: ListRequest = {}): Promise<ListResponse<Partial<ProjectMember>>> {
    return this.apiService.getPromise(`/users/${userId}/projects`, options);
  }

  listMembers(projectId: string, options: ListRequest = {}): Promise<ListResponse<Partial<ProjectMember>>> {
    return this.apiService.postPromise(`/projects/${projectId}/members/list`, options);
  }

  updateMember(projectId: string, userId: string, data: Partial<ProjectMember>): Promise<ProjectMember> {
    return this.apiService.patchPromise(`/projects/${projectId}/members/${userId}`, data);
  }

  addMember(projectId: string, data: Partial<ProjectMember>): Promise<ProjectMember> {
    return this.apiService.postPromise(`/projects/${projectId}/members`, data);
  }

  removeMember(projectId: string, userId: string): Promise<void> {
    return this.apiService.deletePromise(`/projects/${projectId}/members/${userId}`);
  }

  createServiceAccount(projectId: string, data: { name: string; role: ProjectMemberRole }): Promise<ServiceAccount> {
    return this.apiService.postPromise(`/projects/${projectId}/service-accounts`, data);
  }
}
